import React from "react"
import { TermsLayout } from "../TermsLayout/TermsLayout"
import { CardAccordion } from "../../../molecules/CardAccordion"

import * as S from "./style"

const TermsOfServicePage: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>("")

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <TermsLayout>
      <S.StyledCard>
        <S.CardTopContent>
          <h1>Terms of Service</h1>
          <p>Last updated: August 15, 2021</p>
          <p>
            As You decide to create an account in the LearnTray, it is expected
            from You to agree and comply with the Terms of Service presented
            below. Please read these Terms of Service carefully before using Our
            Service.
          </p>
          <p>
            If you have any questions or concerns about these Terms and
            Conditions, You can contact Us via email at{" "}
            <S.StyledLink href="mailto:contact@learntray.com">
              contact@learntray.com
            </S.StyledLink>
            .
          </p>
        </S.CardTopContent>
        <CardAccordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          name="panel1"
          summary="1. Introduction"
        >
          <p>
            These are the Terms of Service governing the use of this Service and
            the agreement that operates between You and the Company. These Terms
            of Service set out the rights and obligations of all users regarding
            the use of the Service.
          </p>
          <p>
            Your access to and use of the Service is conditioned on Your
            acceptance of and compliance with these Terms of Service. These
            Terms of Service apply to all visitors, users and others who access
            or use the Service.
          </p>
          <h2>1.1. Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2>1.2. Definitions</h2>
          <p>For the purposes of these Terms of Service:</p>
          <ul>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Poland
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to LearnTray.
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Terms of Service</strong> (also referred as
                &quot;Terms&quot;) mean these Terms of Service that form the
                entire agreement between You and the Company regarding the use
                of the Service. This Terms of Service agreement has been created
                with the help of the{" "}
                <a
                  href="https://www.privacypolicies.com/terms-conditions-generator/"
                  target="_blank"
                >
                  Terms of Service Generator
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                <strong>Third-party Social Media Service</strong> means any
                services or content (including data, information, products or
                services) provided by a third-party that may be displayed,
                included or made available by the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to LearnTray, accessible from{" "}
                <a
                  href="https://learntray.com"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://learntray.com
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
        </CardAccordion>
        <CardAccordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          name="panel2"
          summary="2. User relations with LearnTray"
        >
          <h2>2.1. What You can expect from Us</h2>
          <h3>Educational platform</h3>
          <p>
            LearnTray is a platform which aim is to provide the learning content
            closer to You. This platform is the subject to these terms. We will
            make many effords to make this product even better and hopefully
            bring to You useful educational functionalities. Some of these
            functionalities at the current moment includes services like
            collection of educational courses or list of saved user courses.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            Notwithstanding any damages that You might incur, the entire
            liability of the Company and any of its suppliers under any
            provision of this Terms and Your exclusive remedy for all of the
            foregoing shall be limited to the amount actually paid by You
            through the Service or 100 USD if You haven't purchased anything
            through the Service.
          </p>
          <p>
            To the maximum extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>
          <p>
            Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply. In
            these states, each party's liability will be limited to the greatest
            extent permitted by law.
          </p>
          <h3>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h3>
          <p>
            The Service is provided to You &quot;AS IS&quot; and &quot;AS
            AVAILABLE&quot; and with all faults and defects without warranty of
            any kind. To the maximum extent permitted under applicable law, the
            Company, on its own behalf and on behalf of its Affiliates and its
            and their respective licensors and service providers, expressly
            disclaims all warranties, whether express, implied, statutory or
            otherwise, with respect to the Service, including all implied
            warranties of merchantability, fitness for a particular purpose,
            title and non-infringement, and warranties that may arise out of
            course of dealing, course of performance, usage or trade practice.
            Without limitation to the foregoing, the Company provides no
            warranty or undertaking, and makes no representation of any kind
            that the Service will meet Your requirements, achieve any intended
            results, be compatible or work with any other software,
            applications, systems or services, operate without interruption,
            meet any performance or reliability standards or be error free or
            that any errors or defects can or will be corrected.
          </p>
          <p>
            Without limiting the foregoing, neither the Company nor any of the
            company's provider makes any representation or warranty of any kind,
            express or implied: (i) as to the operation or availability of the
            Service, or the information, content, and materials or products
            included thereon; (ii) that the Service will be uninterrupted or
            error-free; (iii) as to the accuracy, reliability, or currency of
            any information or content provided through the Service; or (iv)
            that the Service, its servers, the content, or e-mails sent from or
            on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to You. But in such a case the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
          <h3>Termination</h3>
          <p>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms of Service.
          </p>
          <p>
            Upon termination, Your right to use the Service will cease
            immediately.
          </p>
          <h3>Links to Other Websites</h3>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by the Company.
          </p>
          <p>
            The Company tries to exclude from the existence of links to content
            on the site that could harm the user, although at the end, the
            Company has no control over, and assumes no responsibility for, the
            content, privacy policies, or practices of any third party web sites
            or services. You further acknowledge and agree that the Company
            shall not be responsible or liable, directly or indirectly, for any
            damage or loss caused or alleged to be caused by or in connection
            with the use of or reliance on any such content, goods or services
            available on or through any such web sites or services.
          </p>
          <p>
            We strongly advise You to read the terms and conditions and privacy
            policies of any third-party web sites or services that You visit.
          </p>
          <h2>2.2. What We expect from You</h2>
          <h3>Acknowledgment</h3>
          <p>
            By accessing or using the Service You agree to be bound by these
            Terms of Service. If You disagree with any part of these Terms and
            Conditions then You may not access the Service.
          </p>
          <p>
            To use Service you must be 16 years of age or older. If you are 16
            or older but under 18 years of age (or the legal age of your
            country, if greater), you must have your parent or legal guardian's
            permission to use Service and to accept the Terms.
          </p>
          <p>
            Your access to and use of the Service is also conditioned on Your
            acceptance of and compliance with the Privacy Policy of the Company.
            Our Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your personal information when You
            use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy
            carefully before using Our Service.
          </p>
          <h3>Respect others</h3>
          <p>
            Some of our services may allow you to interact with others. We want
            to maintain a respectful environment for everyone, which means that
            you must follow these basic rules of conduct:
          </p>
          <ul>
            <li>
              <p>
                Comply with applicable laws, including export control, sanctions
                and human trafficking laws.
              </p>
            </li>
            <li>
              <p>
                Respect the rights of others, including privacy and intellectual
                property rights.
              </p>
            </li>
            <li>
              <p>
                Don’t abuse or harm others or yourself (or threaten or encourage
                such abuse or harm) – for example, by misleading, defrauding,
                defaming, bullying, harassing or stalking others.
              </p>
            </li>
            <li>
              <p>Don’t abuse, harm, interfere with, or disrupt the services.</p>
            </li>
          </ul>
          <h3>Permission to use Your content</h3>
          <p>
            Some of Our services might be designed to let You upload, submit,
            store, send, receive or share Your content. You have no obligation
            to provide any content to Our services and You’re free to choose the
            content that You want to provide. If You choose to upload or share
            content, please make sure that You have the necessary rights to do
            so and that the content is lawful.
          </p>
        </CardAccordion>
        <CardAccordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          name="panel3"
          summary="3. Governing Law and Disputes Resolution"
        >
          <p>
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </p>
          <h2>3.1. Disputes Resolution</h2>
          <p>
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </p>
          <h2>3.2. For European Union (EU) Users</h2>
          <p>
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </p>
          <h2>3.3. United States Legal Compliance</h2>
          <p>
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a &quot;terrorist
            supporting&quot; country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </p>
          <h2>3.4. Severability and Waiver</h2>
          <h3>Severability</h3>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <h3>Waiver</h3>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under this Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall be the waiver of a
            breach constitute a waiver of any subsequent breach.
          </p>
          <h2>3.5. Translation Interpretation</h2>
          <p>
            These Terms of Service may have been translated if We have made them
            available to You on our Service. You agree that the original English
            text shall prevail in the case of a dispute.
          </p>
        </CardAccordion>
        <CardAccordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          name="panel4"
          summary="4. Changes to These Terms of Service"
        >
          <p>
            We reserve the right, at Our sole discretion, to modify or replace
            these Terms at any time. If a revision is material We will make
            reasonable efforts to provide at least 30 days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at Our sole discretion.
          </p>
          <p>
            By continuing to access or use Our Service after those revisions
            become effective, You agree to be bound by the revised terms. If You
            do not agree to the new terms, in whole or in part, please stop
            using the website, the Service and delete and an account. As it is
            described in Privacy Policy, You may delete an account on website if
            it is possible or submit a request for delete via email at{" "}
            <S.StyledLink href="mailto:contact@learntray.com">
              contact@learntray.com
            </S.StyledLink>
            .
          </p>
        </CardAccordion>
      </S.StyledCard>
    </TermsLayout>
  )
}

export { TermsOfServicePage }
