import React from "react"

import { TermsOfServicePage } from "../components/sets/terms/TermsOfServicePage"
import { SEO } from "../components/atoms/SEO"

const TermsOfService: React.FC = () => {
  return (
    <>
      <SEO />
      <TermsOfServicePage />
    </>
  )
}

export default TermsOfService
